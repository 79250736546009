import React from 'react';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import NotFound from '~/components/Errors/NotFound';

const Favicon = require('public/favicon.ico');

const Error = () => {
  return (
    <div>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"
        />
        <meta name="renderer" content="webkit" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <link rel="shortcut icon" href={Favicon} type="image/x-icon" />
        <link rel="stylesheet" href="/style/desktop/reset.css" />
        <link rel="stylesheet" href="/style/desktop/common.css" />
      </Head>
      <NextSeo title="新得宝贝" description="母婴服务放心选" />
      <NotFound />
    </div>
  );
};

export default Error;
