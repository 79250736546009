import React from 'react';
import Link from 'next/link';

import css from './Errors.less';

const ErrorIcon = require('~/static/error/404.png');

const NotFound = () => {
  return (
    <div className={`flex-column-center ${css.content}`}>
      <img src={ErrorIcon} className={css.icon} alt="404" />
      <h2 className={css.msg}>很抱歉，您要访问的页面不存在</h2>
      <Link href="/">
        <a className={css.btn}>返回首页</a>
      </Link>
    </div>
  );
};

export default NotFound;
